import React, { Component } from 'react';
import { FaTimes } from 'react-icons/fa';

class DD extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showComponent1: true,
        };
      }

render() {
return (
<div className="DD">
<div className="close"  onClick={this.props.onClose}
      style={{cursor: 'pointer'}}>
 <FaTimes color="white" size="28"/>
</div>

<div className="title">
 <h1>Designing and Development</h1>
</div>

<div className="DDContent1">
<div className="DDC1Image">
<img src="/images/website_design.jpeg" />
</div>

 <div className="DDC1C">
 <h1>Website Design</h1>
 <p>
 A well-designed website can help you form a good impression on your prospective 
 customers. It can also help you nurture your leads and get more conversions. 
 But, more importantly, it provides good user experience and helps your website 
 visitors access and navigate your website with ease.<br></br><br></br>
And we create different types of websites like:<br></br>
	•	Homepages. — The homepage is your site's main hub and serves as the face of 
    a brand <br></br>
	•	Magazine websites <br></br>
	•	E-commerce websites <br></br>
	•	Blogs <br></br>
	•	Portfolio websites <br></br>
	•	Landing pages <br></br>
	•	Social media websites <br></br>
	•	Directory and contact pages
 </p>
 </div>

 <div className="DDContent2">
<div className="DDC2Image">
<img src="/images/webapp.png" />
 </div>
 <div className="DDC2C">
<h1>Web Application</h1>
  <p>Web design is important because it impacts how your audience perceives your 
      brand. The impression you make on them can either get them to remain on your
       page and learn about your business or leave your page and turn to a competitor. 
      A good web design helps you keep your leads on your page.<br></br>
Web Applications deliver many business benefits compared to office based solutions.
 Reduce business costs - less time spent talking to customers over the phone; 
 eliminate printed materials; allow users to update their own details. 
 Centralised data is secure and easy to backup. Quick and easy updates.<br></br><br></br>
The Benefits of Web Applications:<br></br>
	•	Improved Efficiency<br></br>
	•	24 / 7 Accessibility<br></br>
	•	Higher Levels of Security <br></br>
	•	Easy Customisable and Scalable<br></br>
	•	Easy Installation and Maintenance<br></br>
 </p>
  </div>
 </div>
</div>


<div className="DDContent3">
 
<div className="DDC3Image">
<img src="/images/mobile_app.jpeg" />
 </div>
 
 <div className="DDC3C">
  <h1>Mobile Application</h1>
  <p>Mobile Apps work as a great marketing tool and help your 
    business get ahead of competitors. Be it providing information
    about new launches, offers, discounts, booking, newsfeed or the 
    related thing, a mobile app is capable of doing all these quite 
    efficiently and allows you to resolve customer requests, makes 
    them aware of the discounts or offers you are providing, gives 
    a platform to directly interact with the company and suggest 
    services the way they want.  <br></br>
Advantages of Mobile Application for business:<br></br>
	•	Increase Brand Presence <br></br>
	•	Improve Customer Service <br></br>
	•	Offers High Scalability <br></br>
	•	Secures Your App Data <br></br>
	•	Improves Customer Relationship <br></br>
	•	Facilitates New Client Data Retrieval <br></br>
	•	Provides Real-time Project Access.
</p>
 </div>
</div>


<div>

</div>
  


</div>  
  );
 }
}

export default DD;
