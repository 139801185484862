import React, { Component } from 'react';
//import { FaArrowRight } from "react-icons/fa";
//import { FaArrowLeft } from "react-icons/fa";

class Portfolio extends Component {
  render() {
    if(this.props.data){
      var networks1= this.props.data.social_team_1.map(function(network1){
        return <li key={network1.name}><a href={network1.url}><i 
        className={network1.className}></i></a></li>
      })
    }
    if(this.props.data){
      var networks2= this.props.data.social_team_2.map(function(networks2){
        return <li key={networks2.name}><a href={networks2.url}><i 
        className={networks2.className}></i></a></li>
      })
    }

    if(this.props.data){
        var networks3= this.props.data.social_team_3.map(function(network3){
          return <li key={network3.name}><a href={network3.url}><i 
          className={network3.className}></i></a></li>
     })
    }

    if(this.props.data){
        var networks4= this.props.data.social_team_4.map(function(networks4){
          return <li key={networks4.name}><a href={networks4.url}><i 
          className={networks4.className}></i></a></li>
     })
    }

    if(this.props.data){
          var networks5= this.props.data.social_team_5.map(function(networks5){
            return <li key={networks5.name}><a href={networks5.url}><i 
            className={networks5.className}></i></a></li>
      })
    }  
     
    if(this.props.data){
          var networks6= this.props.data.social_team_6.map(function(networks6){
            return <li key={networks6.name}><a href={networks6.url}><i 
            className={networks6.className}></i></a></li>
          })
    }
     
  return (
    <section id="team">
  <div className="resume">
    <div className="title">
     <h1>Our Team</h1>
    </div>

<div className="set">
  <div className="icon-holder1">
    <img src="/images/murtuza-profile-pic.jpeg" />
    <div className="socialNet"><ul>{networks1}</ul></div>
  <h1>Murtuza Lakdawala<br></br><strong>Managing Director</strong></h1>
  </div>
  
</div>

<div className="set">
 <div className="icon-holder1">
  <img src="/images/profilepic.jpg" />
  <div className="socialNet"><ul>{networks2}</ul></div>
  <h1>Ishfaq Qureshi <br></br><strong>Key Account Manager</strong></h1>
 </div>
</div>

<div className="set">
 <div className="icon-holder1">
  <img src="/images/ashton.jpeg" />
  <div className="socialNet"><ul>{networks3}</ul></div>
  <h1>Ashton Misquith <br></br><strong>Videographer</strong></h1>
 </div>
</div>

<div className="set">
 <div className="icon-holder1">
  <img src="/images/hadaya_p2.jpg" />
  <div className="socialNet"><ul>{networks4}</ul></div>
  <h1>Hadaya Qaiser <br></br><strong>Web Designer</strong></h1>
 </div>
</div>

<div className="set">
 <div className="icon-holder1">
  <img src="/images/gayathree_profile.jpeg" />
  <div className="socialNet"><ul>{networks5}</ul></div>
  <h1>Gayathri Balasubramanian <br></br><strong>Web Developer</strong></h1>
 </div>
</div>

<div className="set">
 <div className="icon-holder1">
  <img src="/images/edison.jpeg" />
  <div className="socialNet"><ul>{networks6}</ul></div>
  <h1>Edison <br></br><strong>Videographer</strong></h1>
 </div>
</div> 
    
 </div>
</section>
  );
 }
}

export default Portfolio;
