import React, { Component } from 'react';
import { FaTimes } from 'react-icons/fa';

class DM extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showComponent: true,
        };
      }
 

render() {
return (
<div className="DM">
<div className="close"  onClick={this.props.onClose}
      style={{cursor: 'pointer'}}>
 <FaTimes color="white" size="28"/>
</div>

<div className="title">
 <h1>Digital Marketing</h1>
</div>

<div className="DMContent1">

<div className="DMC1Image">
 <img src="/images/instaDC.jpeg" controls></img>
</div>

 <div className="DMC1C">
 <h1>Social Media</h1>
 <p>
 A Digital Marketing channel that helps enjoy increased business visibility and brand awareness and having a 
 professional presence on social media helps reach a broader target audience to secure more leads and 
 convert them into loyal customers.
 <br></br><br></br>
 • Socia Media is an essential piece for business marketing strategies where social platforms help us 
    connect with customers, increase awareness for your brand, and boost sales,
    <br></br>
 • 52 percent of Social Media Marketers believe social media positively influences their company's revenue 
   and sales, <br></br>
 • Get Attention and Build Awareness, <br></br><br></br>
Social media boosts your visibility among potential customers, letting you reach a wide audience by cost and time effective methods.
As it is free to create a business profile on all the major social networks keeping the marketing xosts minimal.<br></br>
<br></br> 
Develop your reputation as a responsive, caring brand by offering support through social channels:<br></br>
	•	Track customer comments, questions, and complaints.<br></br>
	•	Respond to questions and concerns in minimal time.<br></br>
	•	Listen to criticism and make customers feel heard. <br></br>
	•	Know when to resolve public conversations in private messages. <br></br>
<br></br>
Social media is a crucial part of business marketing, and easy to manage. 
Requires creating a profile and start engaging with customers.
As it continues to weave itself into the daily patterns of our lives, more consumers will go to new and 
upcoming social platforms for purchasing decisions. Those who have a strong social media presence 
and branding will increase conversion rates, while those without active social media campaigns 
could lose potential customers. Which company do you want to be?
 </p>
 </div>
</div>


<div className="DMContent2">
 <div className="DMC2Image">
  <img src="/images/SEO.jpeg" />
 </div>
 <div className="DMC2C">
<h1>SEO</h1>
  <p>Allows business owners to create fast and robust websites ranking high in search engines.
SEO keeps the search results fair by reducing the requirement to manipulate them further 
keeping each site at its desired place.
<br></br> <br></br>
SEO improves user experience, making it more likely for customers to be repeated buyers. It results in:
	<br></br>
    •	User-Friendly Websites,
	<br></br>
    •	Well-structured and uncluttered websites makes visitor stay longer,
        decrease bounce rate and increase page views. 
        <br></br>
	•	Most efficient and affordable marketing strategy. 
    <br></br>
	•	Makes a customer get their desired service in lesser time and money.
	<br></br>
    •	Increasing Brand Awareness for websites appearing in initial pages of major search engines like Google.
	<br></br><br></br>
    Growing businesses require better brand awareness and should invest in SEO to start gaining top 
    rankings. Search engines  play a significant role in making or breaking your brand!
</p>
 </div>
</div>


<div className="DMContent3">

 <div className="DMC3Image">
  <img src="/images/adwords1.jpeg" />
 </div>
 
 <div className="DMC3C">
  <h1>Adwords</h1>
  <p>• A simple and effective platform that allows you to creatively use text and 
      images and reach a highly specific audience. <br></br>
    • Ads appear in the search engine results if they are similar to the users search. <br></br>
	• A simple way to get your company listed at the top of the search engines for specific keywords, 
    and you don’t pay for the ad until someone clicks on it. <br></br> <br></br>
	There are a lot of moving parts in a successful online marketing campaign. <br></br>
     SEO, social media marketing, content marketing, website user experience, 
     and pay-per-click advertising all have to work together to keep the wheels and cogs moving 
     nice and smoothly. In this case, though, we’re going to take a closer look at how AdWords can contribute 
    to your online success. You can use AdWords to improve the sales cycle in B2C companies (ecommerce-type businesses)
     as well as B2B companies. It’s all about how you integrate your campaign with your other online 
     marketing efforts.</p>
 </div>
</div>


<div>

</div>
  


</div>  
  );
 }
}

export default DM;
