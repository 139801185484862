import React, { Component } from 'react';
import { FaTimes } from 'react-icons/fa';

class OOH extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showComponent2: true,
        };
      }

render() {
return (
<div className="OOH">
<div className="close"  onClick={this.props.onClose}
      style={{cursor: 'pointer'}}>
 <FaTimes color="white" size="26" />
</div>

<div className="title">
 <h1>Out of Home Advertising</h1>
</div>

<div className="OOHContent1">
<div className="OOHC1Image">
<img src="/images/billboards.jpeg" />
</div>

 <div className="OOHC1C">
 <h1>BillBoards</h1>
 <p>
 When it comes to promote your brand among crowd of diverse background, nothing touches the masses like a Billboard. 
 A Billboard helps your brand becomes peoples chouce and it ensure a widespread reach. With wide variety of locations to offer,
 we ensure your brand gets the right kind of attention that it needs.<br></br> <br></br>From Bur Dubai to Sheikh Zayed Road we have Billboards across
 Dubai to help you reach your target audience in their day-to-day lives. <br></br> <br></br>
 With a fast and agile production and execution team we ensure that your campaign goes live with good speed.
 </p>
 </div>

 <div className="OOHContent2">
<div className="OOHC2Image">
<img src="/images/webapp.png" />
 </div>
 <div className="OOHC2C">
<h1>Car Wraps</h1>
  <p>When people are always on the move, it is estimated that everyday a normal person
    encounters 10-12 public transport vehicle in a day. In a city like Dubai, where 
    everyone is on a move, and more than 20,000 taxis means having your advertisement 
    on taxi will never go unnoticed. <br></br> <br></br> From Printing to pasting, we provide end-to-end 
    solution for taxi advertising but getting appropriate permissions and clearance from authorities. 
 </p>
  </div>
 </div>
</div>

<div>

</div>
  


</div>  
  );
 }
}

export default OOH;
