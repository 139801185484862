import React, { Component } from 'react';

class Header extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var occupation= this.props.data.occupation;
      var description= this.props.data.description;
      var city= this.props.data.address.city;
      var resumeDownload = this.props.data.resumedownload;
      var networks= this.props.data.social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
      })
    }

    return (
   <section id="hme">
      <div className="header">

      <nav id="nav-wrap">

         <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
	      <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

         <ul id="nav" className="nav">
            <li className="current"><a className="smoothscroll" href="#hme">Home</a></li>
            <li><a className="smoothscroll" href="#abt">About</a></li>
	         <li><a className="smoothscroll" href="#res">Services</a></li>
            <li><a className="smoothscroll" href="#work">Our Work</a></li>
            <li><a className="smoothscroll" href="#team">Our Team</a></li>
            <li><a className="smoothscroll" href="#contact">Contact</a></li>
         </ul>

      </nav>

      <div className="row banner">
         <div className="banner-text">
            <h1 className="responsive-headline">Double Click Advertising Agency.</h1>
            <h3 className="responsive-headline">Gets you brand a visibility even in a rainy day</h3>
            <hr />
           <div className="main-col">
            <div className="columns download">
             <p>
               <a href={resumeDownload} className="button"><i className="fa fa-download"></i>Download Company Profile</a>
             </p>
               </div>
               </div> 
               <hr />
            <ul className="social">
               {networks}
            </ul>
         </div>
      </div>

      <p className="scrolldown">
         <a className="smoothscroll" href="#abt"><i className="icon-down-circle"></i></a>
      </p>

   </div>
   </section>
    );
  }
}

export default Header;
