import React, { Component } from 'react';

class About extends Component {
  render() {
   return (
 <section id="abt">
   <div className="about">
    <div className="macImg">
    <img src="/images/macMockFront.png" />
    <div className="macVideo">
    <video src="/images/dcaVideo.mp4" controls autoPlay></video>
    </div>
    </div>

   <div className="title">
      <h1>About Us.</h1>
      <p>Double Click Advertising is part of a group of companies that has been
        in the UAE market for over 42 years. We are a creative advertising agency
        based in Dubai, and we help businesses get visibility across various media
        and reach its pinnicle. </p>
   </div>

   </div>
  </section>
    );
  }
}

export default About;
