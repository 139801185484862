import React, { Component } from 'react';
import { FaArrowRight } from "react-icons/fa";
import * as Scroll from 'react-scroll';
import ReactDOM from "react-dom";

class Testimonials extends Component {

  constructor(props) {
    super(props);
    this.slideref = React.createRef();
  }

   scroll = (scrollOffset) => {
    this.slideref.current.scrollLeft += scrollOffset;
  };


  render() {
    
   return (
  <section id="work">
   <div className="testimonials">
    <div className="title">
     <h1>Our Work</h1>
    </div>

    <div className="Row" ref={this.slideref}>
    <div class="Column1">
     <img src="/images/work1.jpg" />   
    </div>  

    <div class="Column2"> 
    <img src="/images/work2.jpg" /> 
    </div>

    <div class="Column3">
     <img src="/images/w1.jpg" /> 
    </div>

     <div class="Column4"> 
      <img src="/images/w2.jpeg" /> 
     </div>
    </div>
    <div className="prev" onClick={() => this.scroll(-180)}>&#10094;</div>
<div className="next" onClick={() => this.scroll(180)}>&#10095;</div>

   </div>
   </section>
);
  }
}

export default Testimonials;
