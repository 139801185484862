import React, { Component } from 'react';
import DM from './DM';
import DD from './DD';
import OOH from './OOH';

class Resume extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showComponent: false,
      showComponent1: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.closeMeth = this.closeMeth.bind(this);

    this.handleClick1 = this.handleClick1.bind(this);
    this.closeMeth1 = this.closeMeth1.bind(this);

    this.handleClick2 = this.handleClick2.bind(this);
    this.closeMeth2 = this.closeMeth2.bind(this);
  }

  handleClick() {
    window.scrollTo(0, 0);
    this.setState(prevState => ({ showComponent: !prevState.showComponent }));
  }

  closeMeth() {
    window.scrollTo(0, 1380);
     this.setState(prevState => ({ showComponent: !prevState.showComponent }));
  }

  handleClick1() {
    window.scrollTo(0, 0);
    this.setState(prevState => ({ showComponent1: !prevState.showComponent1 }));
  }

  closeMeth1() {
    window.scrollTo(0, 1380);
     this.setState(prevState => ({ showComponent1: !prevState.showComponent1 }));
  }

  handleClick2() {
    window.scrollTo(0, 0);
    this.setState(prevState => ({ showComponent2: !prevState.showComponent2 }));
  }

  closeMeth2() {
    window.scrollTo(0, 1380);
     this.setState(prevState => ({ showComponent2: !prevState.showComponent2 }));
  }

  render() {
    return (
      
  <section id="res">
    <div className="resume">
    {this.state.showComponent ?
           <DM onClose={this.closeMeth} /> :
           null
        }

{this.state.showComponent1 ?
           <DD onClose={this.closeMeth1} /> :
           null
        }

{this.state.showComponent2 ?
           <OOH onClose={this.closeMeth2} /> :
           null
        }
    <div className="title">
     <h1>Services</h1>
    </div>

     <div className="service_box" onClick={this.handleClick}
      style={{cursor: 'pointer'}}>

     <div className="icon-holder">
     <img src="/images/DM.png" />
     </div>
     <h2>Digital Marketing</h2>
     <p>Helps your business reach a wide range of audience
       on web. We are here to help you get a fresh breeze of visibility
       in the digital world.
     </p>
    </div>

    <div className="service_box" onClick={this.handleClick2}
      style={{cursor: 'pointer'}}>
     <div className="icon-holder">
     <img src="/images/OOH.png" />
     </div>
     <h2>Out of Home Advertising </h2>
    <p>From Bur Dubai to Sheikh Zayed Road, we have Billboards across
      Dubai to help you reach your target audience.
    </p>
    </div>

    <div className="service_box" onClick={this.handleClick1}
      style={{cursor: 'pointer'}}>
      <div className="icon-holder">
      <img src="/images/WD.png" />
    </div>
    <h2>Designing & Development </h2>
    <p>With our experienced in-house team, we can develop creative
      websites that best suit your needs.
    </p>
    </div>

    <div className="service_box" onClick={this.handleClick1}
      style={{cursor: 'pointer'}}>
      <div className="icon-holder">
      <img src="/images/WD.png" />
    </div>
    <h2>Printing </h2>
    <p>With our experienced in-house team, we can develop creative
      websites that best suit your needs.
    </p>
    </div>
      
    </div>
    
    </section>
    );
  }
}

export default Resume;
